import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardPrompt from '@artemis/components/StandardPrompt';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  addToCart,
  modifyItemInCart,
  clearPerkError,
} from '@artemis/store/cart/slice';
import { getItemUpdating, getPerkError } from '@artemis/store/cart/selectors';

const PerkConfirmModal = () => {
  const dispatch = useDispatch();
  const perkError = useSelector(getPerkError);
  const isLoading = useSelector(getItemUpdating);

  const handleClose = () => {
    dispatch(clearPerkError());
  };

  const handleConfirm = () => {
    const action = perkError.cartItemId ? modifyItemInCart : addToCart;
    dispatch(
      action({
        merchantId: perkError.merchantId,
        cartItemId: perkError.cartItemId,
        item: perkError.item,
        removeConflictingPerks: true,
      }),
    );
  };

  if (!perkError) {
    return null;
  }

  if (perkError.item.quantity > 1) {
    return (
      <StandardPrompt
        size="sm"
        isOpen={!!perkError}
        isLoading={isLoading}
        isClosable={false}
        content={<FormattedMessage entry="menu.perkError.singleItemOnly" />}
        primaryButtonText={<FormattedMessage entry="global.okay" />}
        onClickPrimaryButton={handleClose}
      />
    );
  }

  const displayHint =
    perkError.displayHint?.toLowerCase() || 'generic_discount';

  return (
    <StandardPrompt
      size="sm"
      isOpen={!!perkError}
      isLoading={isLoading}
      isClosable={false}
      title={
        <FormattedMessage entry={`menu.perkConflictTitle.${displayHint}`} />
      }
      content={
        <FormattedMessage
          entry={`menu.perkConflictDescription.${displayHint}`}
        />
      }
      primaryButtonText={<FormattedMessage entry="menu.applyPerk" />}
      onClickPrimaryButton={handleConfirm}
      secondaryButtonText={<FormattedMessage entry="global.cancel" />}
      onClickSecondaryButton={handleClose}
    />
  );
};

export default PerkConfirmModal;
